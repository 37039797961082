import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Layout from "./views/Layout";
import Home from "./views/Home";
import About from "./views/About";
import Contact from "./views/Contact";
import NoPage from "./views/NoPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}
export default App;

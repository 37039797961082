export const category = [
  {
    id: 1,
    category_img: require("../assets/boy.webp"),
    category_name: "Boys",
    off: false,
    off_amount: 0,
  },
  {
    id: 2,
    category_img: require("../assets/men.webp"),
    category_name: "Men",
    off: false,
    off_amount: 0,
  },
  {
    id: 3,
    category_img: require("../assets/kid.webp"),
    category_name: "New Style",
    off: true,
    off_amount: "15% off on kids wear",
  },
];
export const products = [
  {
    boyswardrobe: [
      {
        id: 1,
        product_category_img: require("../assets/boyspary.jpg"),
        product_category_name: "Party",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 2,
        product_category_img: require("../assets/boyethnic.webp"),
        product_category_name: "Ethnic",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 3,
        product_category_img: require("../assets/boysinner.jpg"),
        product_category_name: "InnerWear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 4,
        product_category_img: require("../assets/boysnightsuits.webp"),
        product_category_name: "Night Suits",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 5,
        product_category_img: require("../assets/boysshoe.webp"),
        product_category_name: "Shoe",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 6,
        product_category_img: require("../assets/boysaccessories.jpg"),
        product_category_name: "Accessories",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 7,
        product_category_img: require("../assets/boysshortstees.webp"),
        product_category_name: "Tees & Shirts",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 8,
        product_category_img: require("../assets/onesisboy.webp"),
        product_category_name: "Onesies",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 9,
        product_category_img: require("../assets/summerboy.webp"),
        product_category_name: "Summer Wear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 10,
        product_category_img: require("../assets/fulllengthboys.webp"),
        product_category_name: "Full Length",
        product_off: false,
        product_off_amount: 0,
      },
    ],
    girlswardrobe: [
      {
        id: 1,
        product_category_img: require("../assets/party.png"),
        product_category_name: "Party",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 2,
        product_category_img: require("../assets/girldress.webp"),
        product_category_name: "Dress",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 3,
        product_category_img: require("../assets/summergirl.webp"),
        product_category_name: "Summer Wear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 4,
        product_category_img: require("../assets/girlsethnic.webp"),
        product_category_name: "Ethnic",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 5,
        product_category_img: require("../assets/girlsinner.webp"),
        product_category_name: "Inner Wear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 6,
        product_category_img: require("../assets/girlsshoe.webp"),
        product_category_name: "Shoe",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 7,
        product_category_img: require("../assets/girlsaccessories.jpg"),
        product_category_name: "Accessories",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 8,
        product_category_img: require("../assets/girlstopandtees.webp"),
        product_category_name: "Top & Tees",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 9,
        product_category_img: require("../assets/onesisgirls.webp"),
        product_category_name: "Onesies",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 10,
        product_category_img: require("../assets/fulllengthgirl.webp"),
        product_category_name: "Full Length",
        product_off: false,
        product_off_amount: 0,
      },
    ],
  },
];
export const carousel = [
  {
    id: 1,
    carousel_img: require("../assets/hero.webp"),
    carousel_logo: require("../assets/logowhite.png"),
    off: false,
    off_amount: 0,
  },
  {
    id: 2,
    carousel_img: require("../assets/hero_two.webp"),
    carousel_logo: require("../assets/logowhite.png"),
    off: false,
    off_amount: 0,
  },
];

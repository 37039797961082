import about from "../assets/about.jpg";

import ProductCard from "../components/ProductCard";
import { products } from "../db//db.js";
const About = () => {
  return (
    <>
      <section className="w-[100vw] overflow-hidden max-w[100vw]">
        <div className="relative p-0  flex items-center align-middle justify-center bg-white">
          <img src={about} alt="img" className="w-full h-auto" />
          <div className="absolute right-0 left-1/2 w-[1/2] overflow-hidden pr-5">
            <h1 className="text-5xl pb-2 font-bold text-[32px] sm:text-2xl lg:text-6xl">
              About us
            </h1>
            <p className="overflow-hidden text-[10px] sm: text-sm text-ellipsis lg:text-lg text-white">
              While some kids choose more casual, comfy attire, others enjoy
              bright, exciting apparel. Santro offers a broad selection of
              styles and trends that are more in line with what children desire
              to wear, their personalities, and the spirit of adventure that is
              inherent in children.
            </p>
          </div>
        </div>
      </section>

      <section className="pt-10 pb-10 p-5 lg:mt-10 max-w-[100vw]">
        <div className="flex align-middle justify-center items-center lg:mt-5 lg:mb-10">
          <h1 className=" text-2xl lg:text-6xl">Products</h1>
        </div>
        <div className="flex flex-row max-w-[100vw] p-10 flex-wrap align-middle justify-evenly ">
          <div className=" w-full lg:w-1/3 flex flex-col align-middle justify-center items-center">
            <h1 className="text-xl lg:text-2xl mb-5 lg:mb-10">
              Boy&apos;s Wardrobe
            </h1>
            <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle">
              {products[0].boyswardrobe.map((e) => {
                return (
                  <div key={e.id}>
                    <ProductCard card={e} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" w-full lg:w-1/3 flex flex-col align-middle justify-center items-center">
            <h1 className="text-xl lg:text-2xl mb-5 lg:mb-10">
              Girl&apos;s Wardrobe
            </h1>
            <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle">
              {products[0].girlswardrobe.map((e) => {
                return (
                  <div key={e.id}>
                    <ProductCard card={e} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

const CategoryCaed = (prop) => {
  const image = prop.card.category_img;
  return (
    <div className="relative max-h-[300px] max-w-[250px] flex flex-col  align-middle justify-center items-center">
      {prop.card.off ? (
        <div className="mb-1 bg-[#e30015] h-1/2 p-3 rounded-md w-full  max-w-[250px] flex items-center justify-center align-middle ">
          <h3 className="text-white text-xl text-center">
            {prop.card.off_amount}
          </h3>
        </div>
      ) : (
        ""
      )}
      <img src={image} alt="category" />
      <h3 className="  text-black">{prop.card.category_name}</h3>
    </div>
  );
};

export default CategoryCaed;

// import { useEffect } from "react";

import Carousal from "../components/Carousal";
import CategoryCaed from "../components/CategoryCaed";
import { carousel, category } from "../db/db.js";
const Home = () => {
  // const res = fetch("../assets/db.json");
  // const data = res.json();

  return (
    <main className="overflow-x-hidden w-[100vw] flex align-middle justify-center items-center flex-col">
      <section className="bg-white flext items-start justify-start align-middle  max-w-[100vw] ">
        <Carousal carousal={carousel} />
      </section>
      <section className="max-w-[100vw] mt-20 mb-10">
        <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle">
          {category.map((e) => {
            return (
              <div key={e.id}>
                <CategoryCaed card={e} />
              </div>
            );
          })}
        </div>
      </section>
      <section className=" p-5 w-[90vw]   ">
        <p>
          Beyond infancy to adolescence, childhood differs from certain
          boundaries in every culture, customary standards regarding
          children&apos;s abilities, limits, and appearance are evident
          throughout the whole growth cycle. Clothes has a significant impact on
          how children appear throughout history. Changes may be seen throughout
          the course of children&apos;s clothes.
        </p>
      </section>
    </main>
  );
};

export default Home;

import { memo, useState } from "react";

// const startAutoplay = () => {
//   setInterval(() => {
//     nextSlidetwo();
//   }, 5000); // Change slide every 3 seconds (adjust as needed)
// };
// startAutoplay();
const Carousal = (prop) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlidetwo = () => {
    setActiveIndex((activeIndex + 1) % prop.carousal.length);
  };

  const dots = prop.carousal.map((e, index) => {
    return (
      <div
        key={e.id}
        className={`
          ${
            activeIndex === index
              ? "h-5 w-5 bg-white rounded-full shadow-lg shadow-slate-500"
              : "h-5 w-5 bg-slate-500 rounded-full"
          }`}
        onClick={() => nextSlidetwo()}
      ></div>
    );
  });
  const img = prop.carousal.map((e, index) => {
    return (
      <div
        key={e.id}
        className={`
          ${
            activeIndex === index
              ? "p-0 relative flex items-center align-middle justify-center bg-white  w-[100vw] overflow-hidden"
              : "block none w-[0]"
          }`}
      >
        <img src={e.carousel_img} alt="img" className="w-full h-auto" />
        {/* <img
          src={e.carousel_logo}
          alt="img"
          className="max-h-[25vh] w-[50vw] absolute bottom-[20%] left-[10vw] "
        /> */}
        <div
          className={`
          ${
            activeIndex === index
              ? "absolute bottom-5 left-[50%]  flex flex-row gap-2 "
              : "block none w-[0]"
          }`}
        >
          {dots}
        </div>
      </div>
    );
  });

  return <div className=" flex flex-row  min-w-[100vw]">{img}</div>;
};

// Carousal.propTypes = {};

export default memo(Carousal);
